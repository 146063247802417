<template>
  <v-container>
    <v-col cols="12" class="text-center">
      <h2>Oblíbené objekty</h2>
    </v-col>
    <v-row v-if="fetchingProperties">
     <v-col cols="3" v-for="i in 4" :key="i">
       <v-skeleton-loader type="image" />
     </v-col>
    </v-row>
    <v-row v-else>
      <v-col
        v-for="property in likedProperties"
        :key="property.id"
        cols="3"
      >
        <CardMiniatureTile
          :property="property"
        ></CardMiniatureTile>
      </v-col>
      <v-col
        cols="12"
      >
        <v-btn
          v-if="likedProperties.length < totalLikedProperties"
          color="primary"
          @click="getProperties"
          :disabled="fetchingProperties"
        >
          Načíst další
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CardMiniatureTile from '../../components/property/CardMiniatureTile'

export default {
  name: 'FavoriteProperties',
  components: { CardMiniatureTile },
  data () {
    return {
      page: 1,
      likedProperties: [],
      totalLikedProperties: 0,
      fetchingProperties: false
    }
  },
  methods: {
    getProperties () {
      this.fetchingProperties = true
      this.$http.get(`/liked-properties?page=${this.page++}`)
        .then(res => {
          /*
            res:
            {
              likedProperties: Object,  - z nejakeho duvodu?; staci si vytahnout Object.values, Object.keys jsou indexy
              total: Number
            }
           */
          this.likedProperties = [...this.likedProperties, ...Object.values(res.data.likedProperties)]
          this.totalLikedProperties = res.data.total
          this.fetchingProperties = false
        })
    }
  },
  created () {
    this.getProperties()
  }
}
</script>

<style scoped>

</style>
