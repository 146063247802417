<template>
    <v-card
      class="mx-auto"
      max-width="350"
      elevation="4"
    >
      <v-img
        height="130px"
        :src="backendPublicUrl + property.miniPhotoPath"
      ></v-img>
      <v-row no-gutters>
        <v-col cols="9" class="col-sm-12 col-md-9">
          <v-card-title class="pt-1">{{ property.name }}</v-card-title>
          <v-card-subtitle><v-icon small style="vertical-align: text-top">mdi-map-marker</v-icon>{{ property.localityDisplayName }}</v-card-subtitle>
          <v-card-text>
            <div class="quick-rating d-flex">
              <span class="reviews-value align-self-center pr-1">{{ property.rating }}</span>
              <v-icon color="amber">mdi-star</v-icon>
              <v-card-subtitle class="reviews-counter pl-1">z {{ property.reviewCount }} Recenzí</v-card-subtitle>
            </div>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
</template>

<script>
export default {
  name: 'CardMiniatureTile',
  props: {
    /*
    * {
    *   id: Number,
    *   name: String,
    *   miniPhotoPath: String,   - cesta bez protokolu, domeny a portu
    *   localityDisplayName: String,
    *   reviewCount: Number
    * }
    */
    property: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      backendPublicUrl: process.env.VUE_APP_BACKEND_PUBLIC_URL
    }
  }
}
</script>

<style scoped>
.object-price{
  font-size: 20px;
  font-weight: 400;
}
>>>.reviews-counter{
  padding-top: 0;
  padding-bottom: 0;
}
</style>
